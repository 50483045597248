section.our-principles {

	.wysiwyg-content {
		padding-top:$margin_mobile;

		@include breakpoint(tablet-v) {
			padding-top:$margin_tablet;
		}
		@include breakpoint(desktop) {
			padding-top:0;
		}
	}
}
.pillars {

	&.pillars--desktop {
		display:none;
		@include breakpoint(tablet-v) {
			display:flex;
			flex-direction:row;
			flex-wrap:wrap;
		}
	}
	&.pillars--mobile {
		display:block;
		@include breakpoint(tablet-v) {
			display:none;
		}
	}

	@include breakpoint(tablet-v) {
		margin-top:50px;
	}
}
.pillar {
	padding-top:10px;

	@include breakpoint(tablet-v) {
		width:calc((480/1064)*100%);
		margin:70px calc((26/1064)*100%) 0;
		padding-top:0;
		// margin-top:70px;
	}
}
.pillar__graphic {
	position:relative;

	@include breakpoint(tablet-v) {
		width:100%;
		height:180px;
	}

	div.masked {
		height:100%;
		background-color:$color_teal-dark;
		mask-size:contain;
		-webkit-mask-size:contain;
		mask-repeat:no-repeat;
		-webkit-mask-repeat:no-repeat;
		mask-position:center;
		-webkit-mask-position:center;
		margin:auto;
		padding-bottom:180px;

		&.onion {
			width:128px;
		}
		&.sunglasses {
			width:240px;
		}
		&.checklist {
			width:124px;
		}
		&.watermelon {
			width:193px;
		}
		&.diamond {
			width:137px;
			// border:10px solid black;
		}
		&.okay {
			width:100px;
		}
		&.messages {
			width:165px;
		}
		&.graduate {
			width:176px;
		}

		@include breakpoint(tablet-v) {
			padding-bottom:0;
		}
	}

	img {
		display:block;
		position:relative;
		width:100%;
		max-height:180px;
		margin:auto;

		&.onion {
			max-width:128px;
		}
		&.sunglasses {
			max-width:240px;
		}
		&.checklist {
			max-width:124px;
		}
		&.watermelon {
			max-width:193px;
		}
		&.diamond {
			max-width:137px;
		}
		&.okay {
			max-width:100px;
		}
		&.messages {
			max-width:165px;
		}
		&.graduate {
			max-width:176px;
		}

		@include breakpoint(tablet-v) {
			position:absolute;
			left:50%;
			top:50%;
			transform:translate(-50%, -50%);
		}
	}
}

.pillar .wysiwyg-content {
	@extend %text-style-1;
	color:$color_gray-dark;
	text-align:left;
	margin-top:25px;

	h2 {
		@extend %heading-style-8;
		color:$color_green;
		margin:0;
	}

	@include breakpoint(tablet-v) {
		text-align:center;
	}
}