section.fundamentally-different {
	position:relative;
	// background-image:url('../../images/about-us/graphic-leaf-standing.svg');
	// background-position:calc((1193/1440)*100%) 100%;
	// background-repeat:no-repeat;
	// background-size:20%;
	color:$color_teal-dark;
	max-width:1440px;
	margin:0 auto;
	padding:0;
	padding-bottom:50vh;
	// padding-bottom:calc((724/1440)*100%);
	pointer-events:none;

	&:before {
		content:' ';
		background-image:url('../../images/about-us/graphic-leaf-standing.svg');
		background-position:calc((1340/1440)*100%) 100%;
		background-repeat:no-repeat;
		// background-size:20%;
		background-size:40%;
		position:absolute;
		bottom:0;
		bottom:calc((18/440)*-100%);
		width:100%;
		// padding-bottom:calc((724/1440)*100%);
		padding-bottom:50vh;
		opacity:0.27;
		z-index:-10;
	}

	@include breakpoint(mobile-h) {
		padding-bottom:calc((724/1440)*100%);

		&:before {
			background-size:20%;
			padding-bottom:calc((724/1440)*100%);
		}
	}
	@include breakpoint(desktop) {
		// padding-top:calc((280/1440)*100%);
		// padding-bottom:calc((220/1440)*100%);
	}
	@include breakpoint(desktop-xl) {
		// padding-top:280px;
		// padding-bottom:220px;
		padding-bottom:724px;
	}

	.container {
		position:absolute;
		// width:100%;
		top:50%;
		left:50%;
		// transform:translateY(-50%);
		transform:translate(-50%, -50%);
		width:calc((1064/1440)*100%);
		max-width:1064px;
		margin:0 !important;
	}
}
.fun-diff__content {
	// padding-top:20%;
	// padding-bottom:20%;
}
.fun-diff__text-light {
	@extend %special-style-1;
	@include font-size(17px);

	@include breakpoint(mobile-h) {
		font-size:3.611vw;		
	}
	@include breakpoint(desktop-xl) {
		@include font-size(52px);
	}
}
.fun-diff__text-heavy {
	@extend %special-style-2;
	@include font-size(30px);

	@include breakpoint(mobile-h) {
		font-size:5.764vw;
	}
	@include breakpoint(desktop-xl) {
		@include font-size(83px);
	}
}

.our-clients .wysiwyg-content {
	@extend %text-style-1;
}

section.mission {
	background:url('../../images/about_us-bg-white_leaves.jpg') center / cover no-repeat;
	padding:0;
}
.mission__content {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#004e42+20,25a94a+100&0.91+0,0.91+100 */
	background: -moz-linear-gradient(45deg,  rgba(0,78,66,0.91) 0%, rgba(0,78,66,0.91) 20%, rgba(37,169,74,0.91) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg,  rgba(0,78,66,0.91) 0%,rgba(0,78,66,0.91) 20%,rgba(37,169,74,0.91) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg,  rgba(0,78,66,0.91) 0%,rgba(0,78,66,0.91) 20%,rgba(37,169,74,0.91) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8004e42', endColorstr='#e825a94a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

	@include breakpoint(desktop) {
		width:866px;
		margin-left:auto;
		
	}
}
.mission__content .wysiwyg-content {
	@extend %text-style-7;
	color:$color_white;
	padding:60px 90px;
	padding:(.667*$margin_mobile) $margin_mobile;

	ul {
		padding-left:20px;
	}
	li {
		margin-bottom:37px;
		margin-bottom:1.233em;

		&:last-of-type {
			margin-bottom:0;
		}
	}

	@include breakpoint(mobile-h) {
		padding:(.667*$margin_tablet) $margin_tablet;
	}
	@include breakpoint (tablet-v) {

	}
	@include breakpoint (desktop) {
		padding:60px 90px;
	}
}

body.about-us .story__content {

	h2 {
		@extend %heading-style-9;
		margin:0;
	}
	p, ul {
		@extend %text-style-7;
		// margin:23px 0;
		margin:.767em 0;
	}

}

section.about-us-aspen {
	position:relative;
	padding:0;

	.container-1440,
	.aspen-fact-anim-container {
		position:relative;
		pointer-events:none;
		// padding-bottom:68.542%;

		// background-image:url('../../images/about-us/graphic-branches.svg');
		// background-position:calc((726.5/1440)*100%) 100%;
		// background-repeat:no-repeat;
		// background-size:calc((1000/1440)*100%);


		@include breakpoint(tablet-v) {
			background-image:url('../../images/about-us/graphic-branches.svg');
			background-position:calc((726.5/1440)*100%) 100%;
			background-repeat:no-repeat;
			background-size:calc((473/1440)*100%);

			padding-bottom:68.542%;
		}
		@include breakpoint(desktop-xl) {
			padding-bottom:987px;
		}

		&.leaves-container {
			@include breakpoint(tablet-v) {
				position:relative;
				margin-top:-68.542%;
				width:100%;
				height:0;
				background:transparent;
			}
		    @include breakpoint("min-width:#{$max-screen-width}") {
		        margin-top:calc(-.68542 * 1440px);
		    }
		}
		&.aspen-fact-anim {
			@include breakpoint("max-width:767px") { /* Override js-reveal */
				visibility: visible!important;
			    -webkit-transform: scale(1)!important;
			    opacity: 1!important;
			    transform: scale(1)!important;
			    opacity: 1!important;
			    -webkit-transition: -webkit-transform 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s, opacity 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s !important;
			    transition: transform 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s, opacity 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s !important;
			}
			@include breakpoint(tablet-v) {
				background:transparent;
				position:relative;
				top:0;
				left:0;
				width:100%;
				// opacity:0;
			}
			@include breakpoint(desktop-xl) {
				left:50%;
				transform:translateX(-50%)!important;
			}
		}
		&.js-reveal-tree {
			@include breakpoint(tablet-v) {
				bottom:0;
			}
		}
	}

	.aspen-fact-anim-container {
		@include breakpoint("max-width:767px") { /* Override js-reveal */
			visibility: visible!important;
		    -webkit-transform: scale(1)!important;
		    opacity: 1!important;
		    transform: scale(1)!important;
		    opacity: 1!important;
		    -webkit-transition: -webkit-transform 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s, opacity 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s !important;
		    transition: transform 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s, opacity 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s !important;
		}
		@include breakpoint(tablet-v) {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			background:transparent;
			// opacity:0;
		}
	}
}
.aspen-fact {
	@extend %text-style-1;
	color:$color_teal-dark;
	// font-size:1.597vw;
	padding-left:$margin_mobile;
	padding-right:$margin_mobile;

	@include breakpoint("max-width:767px") {
		top:auto !important;
		left:auto !important;
		width:auto !important;
		max-width:100% !important;
		text-align:left !important;

		/* Override js-reveal */
		visibility: visible!important;
	    -webkit-transform: scale(1)!important;
	    opacity: 1!important;
	    transform: scale(1)!important;
	    opacity: 1!important;
	    -webkit-transition: -webkit-transform 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s, opacity 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s !important;
	    transition: transform 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s, opacity 1.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0.6s !important;
	}
	@include breakpoint(tablet-v) {
		position:absolute;
		font-size:1.597vw;
		padding:0;
	}
	@include breakpoint(desktop-xl) {
		@include font-size(23px);
	}
}
.about-us-aspen .wysiwyg-content {
	color:$color_teal-dark;
	text-align:left;
	padding-bottom:20px;

	p {
		@extend %text-style-7;
		// margin:23px 0;
		margin:1em 0;

		strong, b {
			color:$color_green;
		}
	}

	@include breakpoint(tablet-v) {
		padding-top:94px;
		padding-bottom:106px;
		padding-top:calc((94/1064)*100%);
		padding-bottom:calc((106/1064)*100%);
		text-align:center;
	}
	@include breakpoint(desktop-xl) {
		padding-top:94px;
		padding-bottom:106px;
	}
}


