
// Utility placeholder (%) definitions

// Use to apply clearfix behavior to a parent element containing
// floats (keeps them properly contained)
//
// Usage:
// .container-with-floated-children {
//      @extend %clearfix;
// }

%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// Use to remove the 'standard' body link treatment
// %clear-body-link {
//     border-bottom:none;

//     &:hover {
//         border-bottom:none;
//         font-weight:400;
//         color:inherit;
//     }
// }

// Use to apply the common side margins used on blocks that 
// contain images and text
//
// Usage:
// .container-for-block {
//      @extend %container;
// }

%container {
    margin-left: $margin_mobile;
    margin-right: $margin_mobile;
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        // margin-left: $margin_desktop;
        // margin-right: $margin_desktop;
        margin-left: calc((188/1440)*100%);
        margin-right: calc((188/1440)*100%);
        margin-left: calc(188px - calc((1440px - 100vw)/2));
        margin-right: calc(188px - calc((1440px - 100vw)/2));
        margin-left: calc(188px - calc((1440px - 100vw)/2.971));
        margin-right: calc(188px - calc((1440px - 100vw)/2.971));
        // margin-left: ((188/1440)*100vw) - (100vw - (188/1440)*50vw)
        // margin-left: .951vw * .951vw;
        // @include margin-left-square(188px);
    }

    @include breakpoint("min-width:#{$max-screen-width}") {
        max-width:$max-content-width;
        margin-left:auto;
        margin-right:auto;
    }
}

%container-1440 {
    max-width:$supermax-content-width;
    margin-left:auto;
    margin-right:auto;
}

%container-nav {
    margin-left: $margin_mobile;
    margin-right: $margin_mobile;
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        margin-left: $margin_nav_desktop;
        margin-right: $margin_nav_desktop;
    }

    @include breakpoint("min-width:#{$max-screen-width}") {
        max-width:$max-nav-content-width;
        margin-left:auto;
        margin-right:auto;
    }
}

%container-tight-mobile {
    margin-left: calc(#{$margin_mobile} + 40px);
    margin-right: calc(#{$margin_mobile} + 40px);
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        margin-left: $margin_desktop;
        margin-right: $margin_desktop;
    }

    @include breakpoint("min-width:#{$max-screen-width}") {
        max-width:$max-content-width;
        margin-left:auto;
        margin-right:auto;
    }

}

%container-narrower {
    margin-left: $margin_mobile;
    margin-right: $margin_mobile;
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        // margin-left: $margin_desktop;
        // margin-right: $margin_desktop;
    }

    @include breakpoint(desktop) {
    // @include breakpoint("min-width:#{$max-screen-width}") {
        max-width:940px;
        margin-left:auto;
        margin-right:auto;
    }
}

%container-1000 {
    margin-left: calc(#{$margin_mobile} + 0px);
    margin-right: calc(#{$margin_mobile} + 0px);
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        margin-left: $margin_desktop;
        margin-right: $margin_desktop;
    }

    @include breakpoint("min-width:#{$max-screen-width}") {
        max-width:1000px;
        margin-left:auto;
        margin-right:auto;
    }
}
%container-1010 {
    margin-left: calc(#{$margin_mobile} + 0px);
    margin-right: calc(#{$margin_mobile} + 0px);
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        max-width:1010px;
        margin-left: $margin_desktop;
        margin-right: $margin_desktop;
    }

    @include breakpoint(desktop-l) {
        max-width:1010px;
        margin-left:auto;
        margin-right:auto;
    }
}
%container-1120 {
    margin-left: calc(#{$margin_mobile} + 0px);
    margin-right: calc(#{$margin_mobile} + 0px);
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        margin-left: $margin_desktop;
        margin-right: $margin_desktop;
    }

    @include breakpoint("min-width:#{1264px}") {
    // @include breakpoint("min-width:#{$max-screen-width}") {
    // @include breakpoint(desktop-xl) {
        max-width:1120px;
        margin-left:auto;
        margin-right:auto;
    }
}
%container-1296 {
    margin-left: calc(#{$margin_mobile} + 0px);
    margin-right: calc(#{$margin_mobile} + 0px);
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        margin-left: $margin_desktop;
        margin-right: $margin_desktop;
    }

    @include breakpoint("min-width:#{1440px}") {
    // @include breakpoint("min-width:#{$max-screen-width}") {
   // @include breakpoint(desktop-xl) {
        max-width:1296px;
        margin-left:auto;
        margin-right:auto;
    }
}

%container-1400 {
    width:100%;
    max-width:$supermax-content-width;
    margin-left:auto;
    margin-right:auto;
}


// Use to apply the common side margins used on blocks that 
// contain images and text, which has no max width
//
// Usage:
// .container-for-block {
//      @extend %container-nomax;
// }

%container-nomax {
    margin-left: $margin_mobile;
    margin-right: $margin_mobile;
    overflow:hidden;    // To avoid scrolling on left/right scroll reveal elements

    @include breakpoint(tablet-v) {
        margin-left: $margin_tablet;
        margin-right: $margin_tablet;
    }

    @include breakpoint(desktop) {
        margin-left: $margin_desktop;
        margin-right: $margin_desktop;
    }
}

// For items where we need to reserve enough horizontal space to
// accomodate a bolded typeface on rollover (avoids pixel shift
// on neighboring items).  This is to be used in conjunction
// with the fixTextHoverShift() function, where targeted
// item also needs to be assigned.
%hover-fix {
    display:inline-block;

    &:after {
        display: block;
        content: attr(data-title);
        font-weight: 500;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
}

// For items who contain a nested link (eg. WP menu items)
%hover-fix-nested-link {
    > a {
        display:inline-block;
        
        &:after {
            display: block;
            content: attr(data-title);
            font-weight: 500;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }
}



// (See _buttons.scss for button placeholders)