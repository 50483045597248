section.who-we-are {
	background:$color_white;
	color:$color_gray-dark;
	padding-top:0;

	@include breakpoint(desktop) {
		padding-top:139px;
		padding-bottom:116px;
	}
}
section.who-we-are .green-bg-mobile {

	@include breakpoint("max-width:1023px") {
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#004e42+0,25a94a+100 */
		background: rgb(0,78,66); /* Old browsers */
		background: -moz-linear-gradient(left,  rgba(0,78,66,1) 0%, rgba(37,169,74,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left,  rgba(0,78,66,1) 0%,rgba(37,169,74,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right,  rgba(0,78,66,1) 0%,rgba(37,169,74,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004e42', endColorstr='#25a94a',GradientType=1 ); /* IE6-9 */

		margin:0;
		padding:$margin_mobile;
		color:$color_white;
	}
	@include breakpoint(tablet-v) {
		padding:$margin_tablet;
	}
	@include breakpoint(desktop) {
		padding:0;
	}
}
.team {
	padding-top:0;

	@include breakpoint(tablet-v) {
		padding-top:48px;
	}
	@include breakpoint(desktop) {
		// padding-top:96px;
		padding-top:73px;
	}
}
.team-member {
	padding-top:20px;

	&:first-of-type {
		padding-top:0;
	}

	@include breakpoint(tablet-v) {
		padding-top:70px;

	}
	@include breakpoint(desktop) {
		padding-top:140px;
		padding-bottom:0;
	}
}
.team-member__photo-plus {
	// Contains photo and name + quote block.

	@include breakpoint(tablet-v) {
		display:flex;
		flex-wrap:nowrap;
		justify-content:space-between;
	}
}
.team-member__photo {

	@include breakpoint(tablet-v) {
		width:calc((339/1064)*100%);
		max-width:339px;
		margin-right:calc((67/1064)*100%);
	}

	img {
		width:100%;
	}
}
.team-member__plus {
	// Contains name + position and quote
	color:$color_teal-dark!important;
	position:relative;
	padding-top:20px;

	@include breakpoint(tablet-v) {
		width:calc((658/1064)*100%);
		padding-top:0;
	}
}
.team-member__name-position {
	// Contains name and position

}
.team-member__name{
	@extend %heading-style-7;
}
.team-member__position {
	@extend %text-style-5;
	margin-top:14px;
}
.team-member__quote {
	@extend %text-style-6;
	background-image: url('../../images/site/quotes-open.jpg'), url('../../images/site/quotes-close.jpg');
	background-position: left top, right bottom;
	background-repeat: no-repeat, no-repeat;
	background-size: 46px 36px, 46px 36px;
	position:relative;
	width:calc(100%-80px);
	margin-top:14px;
	padding:24px 24px 12px;

	@include breakpoint(tablet-v) {
		background-size: 70px 54px, 70px 54px;
		padding:40px 40px 0px;
	}
}
.team-member__quote-text {
}
.team-member__five-things {
	@extend %text-style-1;
	color:$color_gray-dark;
	margin-top:27px;
	border-left:10px $color_green-light solid;
	padding-left:12px;

	h2 {
		@extend %heading-style-6;
		color:$color_green;
	}
	ul {
		padding-left:22px;
	}

	@include breakpoint(tablet-v) {
		padding-left:20px;
	}
	@include breakpoint(desktop) {
		padding-left:40px;
	}
}

// GENERIC WYSIWYG CONTENT ------------------------------------------

.wysiwyg-content {
	// This is the base treatment of WYSIWYG content found in page-specific content blocks.
	// Follows the main nav in all but Home and Our Clients.

	@extend %text-style-1;

	h1 {
		@extend %heading-style-4;
		margin:0;
	}
	p {
		
	}
}