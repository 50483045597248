body.working-with-us .story {
	position:relative;
	display:flex;
	flex-direction:column;
	justify-content:flex-end;
	height:calc((938/1440)*100vw);
	height:65vh;
	max-height:938px;
	padding:0!important;
	background-position:46% 50%;

	@include breakpoint(tablet-v) {
		height:calc((938/1440)*100vw);
		background-position:50% 0%;
	}
}
body.working-with-us .story .container {

    @include breakpoint("min-width:#{$max-screen-width}") {
		width:100%;
	}
}
body.working-with-us .story__content {
	padding:49px 35px 43px;

	@include breakpoint(tablet-v) {
		padding:65px 46px 57px;	
	}
	@include breakpoint(desktop) {
		padding:88px 124px 77px;
	}

	h1 {
		@extend %heading-style-10;
	}
}

section.working-with-us {
	padding-top:10px;

	@include breakpoint(tablet-v) {
		padding-top:24px;
	}
	@include breakpoint(desktop) {
		padding-top:53px;
		padding-bottom:78px;
	}
}

section.working-with-us .wysiwyg-content {

	h2 {
		@extend %heading-style-6;
		color:$color_green;
		margin-top:0;
	}

	ul {
		list-style-type:none;
		// list-style-image: url("../../images/site/bullet-leaf.svg");
		padding-left:60px;

		li {
			position:relative;
			margin-top:34px;

			&:before {
				position: absolute;
			    left: -60px;
			    top: -5px;

			    // --- BACKGROUND SVG ---
			    content:' ';
				background:url("../../images/site/bullet-leaf.svg") center / contain no-repeat;
				width:40px;
				height:21px;
				
				// --- FONTELLO ---
				// content:'\E807';
				// color:$color_green;
				// font-family:'fontello';
				// display:inline-block;
				// @include font-size(33px);
				// line-height:1;
			}
		}
	}

	@include breakpoint(desktop) {
		ul {
			padding-left:90px;

			li {
				margin-top:51px;

				&:before {
					position: absolute;
				    left: -90px;
				    top: -5px;

				    // --- BACKGROUND SVG ---
					width:59px;
					height:32px;
				}
			}
		}
	}				
}