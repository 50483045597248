
// For injecting breakpoint specifications into definitions.  Can supply
// one of the preset sizes, or pass a query rule as the parameter.
//
// Usage:
// @include breakpoint(mobile-v) { background: red; }
//   - or -
// @include breakpoint("min-width:600px") { color: blue; }

@mixin breakpoint($break) {
    @if $break == "mobile-v" {
        @media (min-width: 320px) { @content; }
    } @else if $break == "mobile-h" {
        @media (min-width: 480px) { @content; }
    } @else if $break == "tablet-v" {
        @media (min-width: 768px) { @content; }
    } @else if $break == "laptop" {
        @media (min-width: 900px) { @content; }
    } @else if $break == "tablet-h" {
        @media (min-width: 1024px) and (orientation: landscape) { @content; }
    } @else if $break == "desktop" {
        @media (min-width: 1024px) { @content; }
    } @else if $break == "desktop-l" {
        @media (min-width: 1200px) { @content; }
    } @else if $break == "desktop-xl" {
        @media (min-width: 1440px) { @content; }
    } @else if $break == "touch" {
        @media (hover:none) { @content; }
    } @else {
        @media ($break) { @content; }
    }
}



// For adding font sizes using 'rem' units.  Value should be supplied
// in 'px' format, and the rem value will be calculated, with a px
// fallback included.
//
//  Usage:
//  @include font-size(12px)

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// // Takes max margin in pixels (w/o px unit).
// // Finds square root in relative units (vw).
// // Calculates margin as square of the relative unit value.
// // This reduces margin "geometrically" as viewport width decreases.
// //
// // Usage:
// // @include margin-left-square(188)

// @mixin margin-left-square($size) {
//     margin-left: $size;
//     margin-left: pxToSquareVw($size);
// }


// For setting up a flex-based grid.  This mixin should be included
// in the child definition of the grid.  A default gutter value 
// will be used if none is provided.
//
//  Usage:
//  .parent-element {
//      display:flex;
//  }
//  .child-element {
//      @include flex-grid(3);   // Generates a 3 column grid, using default gutter
//  }

@mixin flex-grid($numColumns, $gutterWidth: 30px) {
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;

    > * {
        flex-grow:0;
        flex-shrink:1;
        flex-basis: calc((100% - #{($gutterWidth * ($numColumns - 1) + .1)}) / #{$numColumns});
        // margin-right:$gutterWidth;

        &:nth-child(1n) {
            // Clear out our previous right margin settings
            margin-right:$gutterWidth;
        }
        &:nth-child(#{$numColumns}n+#{$numColumns}) {
            margin-right:0;
        }
    }
}