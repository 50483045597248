html {
    // scroll-behavior: smooth;
    color:$color_black;
}

body {
	position:relative;
	margin-top:60px;

	@include breakpoint(mobile-h) {
		margin-top:78px;
	}
	@include breakpoint(desktop) {
		margin-top:0;
	}
}

a {
	color:$color_green;
	text-decoration:none;
    transition:color 0.2s $ease-out-quad, border-color 0.2s $ease-out-quad;

    &:hover {
        text-decoration:none;
    }
}

*:focus {
    outline:0;
}