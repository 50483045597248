//-------------------------------------------------------------------
// Site Header
//-------------------------------------------------------------------

header.header {
	position:absolute;
	width:100%;
}

// MOBILE NAV -------------------------------------------------------

nav.mobile-nav {
    position: fixed;
    top:0;
    height:100vh;
    // padding-top:40px;
    background-color:$color_green;
    width:100%;
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    transform:translateY(-100vh);
    transition:transform 0.5s $ease-in-out-quad;
    z-index:12;
    display:flex;
    align-items:center;
    justify-content:center;

    &.js-is-open {
        transform:translateY(0);
    }

	@include breakpoint(desktop) {
		display:none;
	}

	ul {
		list-style-type:none;
		// padding:80px 0 0;
		margin:0;
		padding:0;
		text-align:center;

		@include breakpoint(tablet-v) {
			// padding: 90px 0 0;
		}

		li {
			padding:10px 0;

			@include breakpoint(desktop) {

			}

			@include breakpoint(desktop-l) {

			}

			@include breakpoint(desktop-xl) {

			}

			&:last-of-type {

			}

			a {
				@extend %nav-style-1;
				color:$color_white;
				text-transform:uppercase;
				text-decoration:none;
				position:relative;

				&.active {
					@extend %nav-style-2;

					&:after {
						content:' ';
						position:absolute;
						left:50%;
						transform:translateX(-50%);
						bottom:-55px;
						width: 0; 
						height: 0; 
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						border-top: 20px solid $color_green;						
					}
				}
			}
		}
	}
}
.mobile-nav-close {
	position:absolute;
	top:0;
	right:10px;

	@include breakpoint(tablet-h) {
		right:20px;
	}
}
.mobile-nav-close-link {
	@extend %icon-style-1;
	color:$color_white;
	text-decoration:none;
}

// DESKTOP NAV ------------------------------------------------------

.header__mobile-navbar {
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	position:fixed;
	top:0;
	width:100%;
	max-height:80px;
	background:$color_white;
	box-shadow: 0 3px 5px 0 rgba(67, 67, 67, 0.4);
	z-index:100;

	@include breakpoint(desktop) {
		display:none;
	}
}
.header__mobile-navbar-branding {
	width:60%;
	max-width:205px;

	@include breakpoint(mobile-h) {
		width:50%;
		max-width:225px;
	}
}
.header__mobile-navbar-branding-link {
	display:block;
	background:url('../../images/site/logo-aspen_grove-horz.svg') center / contain no-repeat;
	padding-bottom:calc((52/196)*100%);
	margin:5px;
	text-indent:-9999px;
	font-size:0;

	@include breakpoint(mobile-h) {
		margin:10px;
	}
	@include breakpoint(tablet-v) {
		margin-left:20px;
	}
}
.header__mobile-menu-icon {
	position:absolute;
	top:50%;
	right:10px;
	transform:translateY(-54%);

	@include breakpoint(tablet-v) {
		right:20px;
	}
	// @include breakpoint(desktop) {
	// 	display:none;
	// }
}
.header__mobile-menu-icon-link {
	// @extend %icon-style-1;
	color:$color_teal-dark;
	text-decoration:none;

	&:after {
		@extend %heading-style-6;
		content:'MENU';

		&.js-close {
		    content:'';
		}
	}
	&:before {
		@extend %icon-style-1;
		&.js-close {
		    content:'\E800';
		}
	}
}

nav.header__nav {
	display:none;
	position:relative;
	top:0%;
	transform:translateY(-50%);
	@extend %container-nav;
	background:$color_green;
	overflow:visible;
	z-index:10;

	@include breakpoint(desktop) {
		display:block;
	}

	ul {
		list-style-type:none;
		margin:0;
		padding:38px calc((50/1440)*100%);
		// text-align:center;
		display:flex;
		flex-direction:row;
		justify-content:space-between;

		li {
			// display:inline;
			// margin-right:3%;

			@include breakpoint(desktop) {
				// margin-right:4%
			}

			@include breakpoint(desktop-l) {
				// margin-right:5%;
			}

			@include breakpoint(desktop-xl) {
				// margin-right:6%;
			}

			&:last-of-type {
				// margin-right:0;
			}

			a {
				@extend %nav-style-1;
				color:$color_white;
				text-transform:uppercase;
				text-decoration:none;
				position:relative;

				&.active {
					@extend %nav-style-2;

					&:after {
						content:' ';
						position:absolute;
						left:50%;
						transform:translateX(-50%);
						bottom:-55px;
						width: 0; 
						height: 0; 
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						border-top: 20px solid $color_green;						
					}
				}
			}
		}
	}
}

