section {
	width:100%;
	padding-top:$margin_mobile;
	padding-bottom:$margin_mobile;

	@include breakpoint(tablet-v) {
		padding-top:$margin_tablet;
		padding-bottom:$margin_tablet;
	}
}

.container {
	@extend %container;
}
.container-1440 {
	@extend %container-1440;
}

body.our-clients,
body.who-we-are,
body.our-principles,
body.working-with-us,
body.about-us {
	section.landing,
	section.intro {
		display:none;

		@include breakpoint(desktop) {
			display:block;
		}
	}
}

section.landing {
	background:url('../../images/home-bg-header-white_trees.jpg') center / cover no-repeat;
	min-height:52.917vw;
	padding:0;

	.container {
		position:relative;
	}

	@include breakpoint(desktop) {
		// height:762px;
	}
	@include breakpoint(desktop-xl) {
		min-height:762px;
		max-height:762px;
	}
}
.contact-link {
	display:none;
	position:relative;
	width:calc((1248/1440)*100%);
	max-width:1248px;
	margin:auto;
	z-index:10;

	a {
		@extend %nav-style-3;
		text-transform:uppercase;
		color:$color_teal-dark;
		position:absolute;
		top:78px;
		right:0;
	}

	@include breakpoint(desktop) {
		display:block;
	}
}
.landing__branding {
}
.landing__branding-link {
	display:block;
	background:url('../../images/site/logo-aspen_grove.svg') center / contain no-repeat;
	width:80%;
	padding-bottom:calc((276/378)*80%);
	margin:6.181vw auto 0;
	text-indent:-9999px;
	font-size:0;

	@include breakpoint(tablet-v) {
		width:26.25vw;
		// width:80%;
		// width:calc(378px + calc((1440px - 100vw)/4.211));
		width:calc(378px + calc((1024px - 100vw)/1.604));
		// height:19.167vw;
		padding-bottom:calc((276/378*calc(378px + calc((1024px - 100vw)/1.604))));
		margin:6.181vw auto 0;
	}

	@include breakpoint(tablet-h) {
		width:378px;
		padding-bottom:276px;
	}
	@include breakpoint(desktop-xl) {
		margin:89px auto 0;
	}
}
.landing__content {
	text-align:center;
	margin-top:6.25vw;

	@include breakpoint(desktop) {
		// margin-top:90px;
	}
	@include breakpoint(desktop-xl) {
		margin-top:90px;
	}

	h1 {
		@extend %heading-style-1;
		color:$color_teal-dark;
		margin:0;
		font-size:5.833vw;

		@include breakpoint(desktop-xl) {
			@include font-size(84px);
		}
	}

	h2 {
		@extend %heading-style-2;
		color:$color_teal-dark;
		// max-width:73.611vw;
		max-width:100%;
		margin:0;
		margin:2.778vw auto;
		@include font-size(17px);

		@include breakpoint(mobile-h) {
			max-width:73.611vw;
			font-size:3.472vw;		
		}
		@include breakpoint(desktop) {
			// margin:40px auto;
		}
		@include breakpoint(desktop-xl) {
			@include font-size(50px);
			max-width:1060px;
			margin:40px auto;
		}
	}
}

section.intro {
	background:url('../../images/home-bg-intro-green.jpg') center / cover no-repeat;
	color:$color_white;

	@include breakpoint(desktop) {
		padding-top:73px;
		padding-bottom:99px;
	}
}
section.intro .container {
    @include breakpoint("min-width:#{$max-screen-width}") {
        max-width:1180px;
    }
}
.intro__content {
	text-align:left;

	h2 {
		@extend %heading-style-3;
		margin-top:0;
	}
	p, ul {
		@extend %text-style-2;
	}
	ul {

	}
}

section.story {
	background:url('../../images/our_clients-bg-yellow_leaves.jpg') center / cover no-repeat;
	color:$color_white;
	padding-top:137px;
	padding-top:75px;
	padding-bottom:0;

	@include breakpoint(tablet-v) {
		padding-top:182px;
		padding-top:140px;
	}
	@include breakpoint(desktop) {
		padding-top:257px;
		padding-bottom:0;
	}
}
.story__content {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#25a94a+0,004e42+80&0.82+0,0.82+100 */
	background: -moz-linear-gradient(top,  rgba(37,169,74,0.82) 0%, rgba(0,78,66,0.82) 80%, rgba(0,78,66,0.82) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(37,169,74,0.82) 0%,rgba(0,78,66,0.82) 80%,rgba(0,78,66,0.82) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(37,169,74,0.82) 0%,rgba(0,78,66,0.82) 80%,rgba(0,78,66,0.82) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d125a94a', endColorstr='#d1004e42',GradientType=0 ); /* IE6-9 */
	padding:35px 35px 10px;
	text-align:center;

	@include breakpoint(tablet-v) {
		padding:46px 46px 14px;	
	}
	@include breakpoint(desktop) {
		padding:65px 124px 19px;
	}

	h2 {
		@extend %heading-style-4;
		margin:0;
	}
	p, ul {
		@extend %text-style-2;
		margin:23px 0;
	}
}

section#contact {
	&:target {
		scroll-behavior:smooth;
	}
}
section.contact {
	background:url('../../images/contact-bg-green_leaves.jpg') center / cover no-repeat;
	color:$color_white;

	@include breakpoint(desktop) {
		padding-top:119px;
		padding-bottom:56px;
	}
}
.contact__content {
	@extend %text-style-2;
	text-align:center;
}
.contact__cta {
	@extend %heading-style-5;
}
.contact__email {
	@extend %text-style-7;
	@include font-size(18px);
	margin-top:40px;

	@include breakpoint(tablet-v) {
		@include font-size(27px);
	}
	@include breakpoint(desktop) {
		@include font-size(32px);
	}
	a {
		color:$color_white;
		text-decoration:none;
	}
}
.contact__phone {
	margin-top:13px;
}
a[href^="tel"] {
	color: inherit; /* Inherit text color of parent element. */
	text-decoration: none; /* Remove underline. */
	/* Additional css `propery: value;` pairs here */
}
.contact__address {
	@extend %text-style-3;
	margin-top:50px;

	ul {
		list-style-type:none;
		padding:0;
		margin:0;
		// text-align:center;

		li {

		}
	}

	@include breakpoint(tablet-v) {
		margin-top:66px;

		ul {
			list-style-type:none;
			padding:0;
			margin:0;

			li {
				display:inline;

				&:after {
					content:'|';
					padding:0 12px;
				}
				&:last-of-type {
					&:after {
						content:'';
						padding:0;
					}
				}

			}
		}
	}
	@include breakpoint(desktop) {
		margin-top:93px;
	}
}

section.disclosure {
	background:$color_off-white;

	@include breakpoint(desktop) {
		padding-top:48px;
		padding-bottom:55px;
	}
}
.disclosure .wysiwyg-content {

	p {
		@extend %text-style-4;
		color:$color_black;
		margin:14px 0;
	}
}

/* TEMP HOME */
body.aspen-grove {
	margin-top:0!important;

	section.landing {
		display:flex;
		align-items:center;
		justify-content:center;
		height:100vh!important;
		min-height:none;
		max-height:none;

		.container {
			width:calc(504/1440 *100%);
			min-width:280px;
			max-width:504px;
			margin:auto;

			@include breakpoint(tablet-v) {
				min-width:380px;
			}
		}
	}
	.landing__branding {
		width:100%;
	}
	.landing__branding-link {
		width:100%;
		margin:0;
		padding-bottom:calc(367/504 *100%);
		// cursor:default;
	}
	.landing__content {

		@include breakpoint(desktop) {
			margin-top:62px;
		}
	}
	.temp-home__email a,
	.temp-home__phone {
		@extend %temp-home-style-1;
		color:$color_teal-dark;
	}
	.temp-home__compliance {
		margin-top:20px;

		a {
			@extend %nav-style-3;
			font-weight:500;
			color:$color_teal-dark;
			text-transform:uppercase;
			letter-spacing:0.1em;
			text-decoration:underline;
		}
	}

}
