section.our-clients {
	background:$color_white;
	color:$color_gray-dark;

	@include breakpoint(desktop) {
		padding-top:80px;
		padding-bottom:104px;
	}
}
.our-clients .wysiwyg-content {
	@extend %text-style-1;
}