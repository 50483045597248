//-------------------------------------------------------------------
// Custom Font Loading (eg. fontello)
//-------------------------------------------------------------------

//-------------------------------------------------------------------
// TT NORMS PRO
//-------------------------------------------------------------------

/* TTNormsPro Regular */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-Regular/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-Regular/font.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

/* TTNormsPro Roman */
@font-face {
    font-family: 'TT Norms Pro Roman';
    src: url('../fonts/TT Norms Pro/TTNormsProVarRoman/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsProVarRoman/font.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

/* TTNormsPro Bold */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-Bold/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-Bold/font.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

/* TTNormsPro BoldItalic */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-BoldItalic/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-BoldItalic/font.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
}

/* TTNormsPro DemiBold */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-DemiBold/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-DemiBold/font.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

/* TTNormsPro ExtraBold */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-ExtraBold/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-ExtraBold/font.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
}

/* TTNormsPro Italic */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-Italic/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-Italic/font.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
}

/* TTNormsPro Light */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-Light/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-Light/font.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

/* TTNormsPro LightItalic */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-LightItalic/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-LightItalic/font.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
}

/* TTNormsPro Medium */
@font-face {
    font-family: 'TT Norms Pro';
    src: url('../fonts/TT Norms Pro/TTNormsPro-Medium/font.woff2') format('woff2'),
         url('../fonts/TT Norms Pro/TTNormsPro-Medium/font.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

//-------------------------------------------------------------------
// FONTELLO
//-------------------------------------------------------------------

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello-aspen-grove/fontello-aspen-grove.eot?36672587');
    src: url('../fonts/fontello-aspen-grove/fontello-aspen-grove.eot?36672587#iefix') format('embedded-opentype'),
         url('../fonts/fontello-aspen-grove/fontello-aspen-grove.woff2?36672587') format('woff2'),
         url('../fonts/fontello-aspen-grove/fontello-aspen-grove.woff?36672587') format('woff'),
         url('../fonts/fontello-aspen-grove/fontello-aspen-grove.ttf?36672587') format('truetype'),
         url('../fonts/fontello-aspen-grove/fontello-aspen-grove.svg?36672587#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
  
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
}
.icon-social-linkedin:before {
    content:'\F0E1';
}
.icon-social-instagram:before {
    content:'\F16D';
}
.icon-social-twitter:before {
    content:'\F099';
}
.icon-social-youtube:before {
    content:'\F16A';
}
.icon-social-facebook:before {
    content:'\F09A';
}
.icon-social-vimeo:before {
    content:'\F27D';
}
.icon-social-email:before {
    content:'\E809';
}
.icon-circle-filled:before {
    content:'\F111';
}
.icon-circle-open:before {
    content:'\F10C';
}
.icon-circle-thin:before {
    content:'\F1DB';
}
.icon-menu-bold:before {
    content:'\F0C9';
}
// .icon-menu-bold.js-close:before,
.icon-close-bold:before {
    content:'\E800';
}
.icon-menu-light:before {
    content:'\E805';
}
.icon-close-light:before {
    content:'\E806';
}
.icon-bullet-leaf:before {
    content:'\E807';
}

