// Colors
$color_black:#000000;
$color_white:#ffffff;
$color_off-white:#e0e9e8;
$color_gray-dark:#2d2d2d;
$color_green:#25a94a;
$color_green-light:#b7e3c3;
$color_teal-dark:#004e42;

// Header Sizes
$header_height_mobile:60px;
$header_height_desktop:112px;

// Margin Sizes
$margin_mobile:20px;
$margin_tablet:30px;
$margin_tablet:48px;
$margin_desktop:188px;

$margin_nav_desktop:96px;
$margin_nav_desktop:6.667%;
// $margin_desktop:13%;
// $margin_desktop:72px;
// $margin_desktop_max:250px;

// Content Sizes
$max-content-width:1064px;
$max-screen-width:$max-content-width + ($margin_desktop * 2) + 16px;  // Add 16 for scrollbar
$supermax-content-width:1440px;

$max-nav-content-width:1248px;

// Easing Equations
$ease-out-quad:cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-quart:cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint:cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-back:cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-quart:cubic-bezier(0.77, 0, 0.175, 1);

// Track z-index layers used by elements affected by overlays
$index_header: 10;

// Easing Equations
$ease-out-quad:cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-quart:cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint:cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-back:cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-quad:cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-quart:cubic-bezier(0.77, 0, 0.175, 1);