//-------------------------------------------------------------------
// Define type styles (can be done as placeholders to extend)
//-------------------------------------------------------------------

// NAV STYLES -------------------------------------------------------

%nav-style-1 {
	// Desktop nav.
	font-family:'TT Norms Pro', sans-serif;
	font-weight:500;
	font-style:normal;
	@include font-size(18px);
	line-height:1;

	@include breakpoint(desktop) {
		@include font-size(19px);
	}
	@include breakpoint(desktop-l) {
		@include font-size(21px);
	}
	@include breakpoint(desktop-xl) {
		@include font-size(23px);
	}
}

%nav-style-2 {
	// Desktop nav selected.
	font-family:'TT Norms Pro', sans-serif;
	font-weight:800;
	font-style:normal;
	@include font-size(18px);
	line-height:1;

	@include breakpoint(desktop) {
		@include font-size(19px);
	}
	@include breakpoint(desktop-l) {
		@include font-size(21px);
	}
	@include breakpoint(desktop-xl) {
		@include font-size(23px);
	}
}

%nav-style-3 {
	// Desktop "CONTACT".
	font-family:'TT Norms Pro', sans-serif;
	font-weight:800;
	font-style:normal;
	@include font-size(18px);
	line-height:1;
	color:$color_teal-dark;

	@include breakpoint(desktop) {
		@include font-size(18px);
	}
	@include breakpoint(desktop-l) {
		@include font-size(19px);
	}
	@include breakpoint(desktop-xl) {
		@include font-size(20px);
	}
}

// HEADING STYLES ---------------------------------------------------

%heading-style-1 {
	// Primary landing header text.
	// Light 84pt / 72pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:300;
	font-style:normal;
	@include font-size(48px);
	line-height:.857;

	@include breakpoint(desktop) {
		@include font-size(84px);
		line-height:.857;
	}
}

%heading-style-2 {
	// Secondary landing header text.
	// Regular 50pt / 72pt
	// Regular 50pt / 65pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(26px);
	line-height:1.3;

	@include breakpoint(tablet-v) {
		@include font-size(36px);
	}
	@include breakpoint(desktop) {
		@include font-size(50px);
		// line-height:1.44;
		line-height:1.3;
	}
}

%heading-style-3 {
	// Intro header text.
	// Regular 50pt / 56pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(26px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(36px);
	}
	@include breakpoint(desktop) {
		@include font-size(50px);
		line-height:1.12;
	}
}

%heading-style-4 {
	// Story header text.
	// Page header text.
	// Regular 41pt / 30pt(?)
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(22px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(29px);
	}
	@include breakpoint(desktop) {
		@include font-size(41px);
	}
}

%heading-style-5 {
	// Contact CTA.
	// Regular 36pt / 42pt(?) uppercase
	font-family:'TT Norms Pro', sans-serif;
	font-weight:700;
	font-style:normal;
	text-transform:uppercase;
	@include font-size(24px);
	line-height:1;

	@include breakpoint(desktop) {
		@include font-size(36px);
	}
}

%heading-style-6 {
	// Who We Are h2.
	// DemiBold 23pt / 30pt(?) uppercase
	font-family:'TT Norms Pro', sans-serif;
	font-weight:600;
	font-style:normal;
	text-transform:uppercase;
	@include font-size(18px);
	line-height:1;

	@include breakpoint(desktop) {
		@include font-size(23px);
		line-height:1.304;
	}
}

%heading-style-7 {
	// Who We Are member name.
	// DemiBold 32pt / 27pt(?)
	font-family:'TT Norms Pro', sans-serif;
	font-weight:600;
	font-style:normal;
	// @include font-size(17px);
	@include font-size(18px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(23px)
	}
	@include breakpoint(desktop) {
		@include font-size(32px);
	}
}

%heading-style-8 {
	// Who We Are member name.
	// Bold 30pt / 30pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:700;
	font-style:normal;
	// @include font-size(16px);
	@include font-size(18px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(26px);
	}
	@include breakpoint(desktop) {
		@include font-size(30px);
	}
}

%heading-style-9 {
	// About Us name heading.
	// Regular 50pt / 59pt(?)
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(27px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(36px);
	}
	@include breakpoint(desktop) {
		@include font-size(50px);
	}
}

%heading-style-10 {
	// Working With Us "Our promises" heading.
	// Regular 65pt / 48pt(?)
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(36px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(48px);
	}
	@include breakpoint(desktop) {
		@include font-size(65px);
	}
}

// TEXT STYLES ------------------------------------------------------

%text-style-1 {
	// Our Clients body text.
	// Who We Are bullets.
	// Regular 23pt / 30pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	// @include font-size(12px);
	// @include font-size(14px);
	@include font-size(16px);
	line-height:1.304;

	@include breakpoint(tablet-v) {
		// @include font-size(16px);
		@include font-size(18px);
	}
	@include breakpoint(desktop) {
		@include font-size(23px);
	}
}

%text-style-2 {
	// Intro body text.
	// Regular 29pt / 37pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(16px);
	line-height:1.275;

	@include breakpoint(tablet-v) {
		@include font-size(21px);
	}
	@include breakpoint(desktop) {
		@include font-size(29px);
	}
}

%text-style-3 {
	// Contact address text.
	// Regular 24pt / 42px(?)
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	// @include font-size(13px);
	@include font-size(14px);
	line-height:1.2;

	@include breakpoint(tablet-v) {
		@include font-size(17px);
	}
	@include breakpoint(desktop) {
		@include font-size(24px);
	}
}

%text-style-4 {
	// Disclosure text.
	// Regular 15pt / 19px
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	// @include font-size(8px);
	@include font-size(10px);
	line-height:1.267;

	@include breakpoint(tablet-v) {
		// @include font-size(11px);
		@include font-size(12px);
	}
	@include breakpoint(desktop) {
		@include font-size(15px);
	}
}

%text-style-5 {
	// Who We Are member position.
	// Regular 25pt / 27pt(?) uppercase
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	text-transform:uppercase;
	// @include font-size(14px);
	@include font-size(16px);
	line-height:1;

	@include breakpoint(tablet-v) {
		@include font-size(18px);
	}
	@include breakpoint(desktop) {
		@include font-size(25px);
		line-height:1;
	}
}

%text-style-6 {
	// Who We Are quote text.
	// Light Italic 39pt / 47pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:300;
	font-style:italic;
	@include font-size(21px);
	line-height:1.205;

	@include breakpoint(tablet-v) {
		@include font-size(28px);
	}
	@include breakpoint(desktop) {
		@include font-size(39px);
	}
}

%text-style-7 {
	// About Us mission text.
	// Regular 30pt / 37px
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(16px);
	line-height:1.233;

	@include breakpoint(tablet-v) {
		@include font-size(27px);
	}
	@include breakpoint(desktop) {
		@include font-size(30px);
	}
}

// SPECIAL STYLES ---------------------------------------------------

%special-style-1 {
	// About Us fundamentally different light.
	// Regular 52pt / 63pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:400;
	font-style:normal;
	@include font-size(52px);
	line-height:1.212;
}

%special-style-2 {
	// About Us fundamentally different heavy.
	// Bold Italic 83pt / 98pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:700;
	font-style:italic;
	@include font-size(83px);
	line-height:1.181;
}

%icon-style-1 {
	// Mobile (burger) menu button.
	@include font-size(32px);

	@include breakpoint(mobile-h) {
		@include font-size(40px);
	}
	@include breakpoint(tablet-v) {
		@include font-size(48px);
	}
}

%temp-home-style-1 {
	// Who We Are member name.
	// DemiBold 34pt / 50pt
	font-family:'TT Norms Pro', sans-serif;
	font-weight:600;
	font-style:normal;
	// @include font-size(17px);
	@include font-size(18px);
	line-height:1.471;

	@include breakpoint(tablet-v) {
		@include font-size(23px);
	}
	@include breakpoint(desktop) {
		@include font-size(34px);
	}
}
